import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';//引入element-ui
import "tailwindcss/tailwind.css"
import "./assets/css/index.css"
Vue.config.productionTip = false
Vue.use(ElementUI);
//路由操作
router.beforeEach((to, from, next) => {
  // 当移动端试图访问pc端界面时
  if (
    /Android|webOS|iPhone|iPod|BlackBerry|iPad/i.test(navigator.userAgent) &&
    to.meta.type !== "mobile"
  ) {
    const routers = router.options.routes.filter((v) => v.name?.toString().startsWith("m") );
    const path = routers?.filter(
      (v) =>
        v.name?.toString().split("_")[1] === to.name?.toString().split("_")[1]
    )[0];
    if (path) {
      next(path.path);
    } else {
      next("/m_index");
    }
  }

  // 当pc端页面试图访问移动端页面时
  if (
    !/Android|webOS|iPhone|iPod|BlackBerry|iPad/i.test(navigator.userAgent) &&
    to.meta.type !== "pc"
  ) {
    const routers = router.options.routes;
    const path = routers.filter(
      (v) =>
        v.name?.toString().split("_")[1] === to.name?.toString().split("_")[1]
    )[0];
    if (path) {
      next(path);
    } else {
      next("/p_index"
      );
    }
  }
  next();
});
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
