<template>
  <div>
    <Header @showService="showService = true"></Header>
    <el-main class="min-width">
      <div class="banner-box">
        <div class="flex max-width items-center h-full">
          <div class="flex-1 product-info" ref="productInfoRef">
            <p class="title">灵龙翻译助理<span></span></p>
            <div class="slogan">聚合社交平台，畅聊不限字符</div>
            <div class="intro">
              灵龙翻译助理是一款集实时双向多语言翻译、客户关系管理于一体的跨境私域管理营销工具，支持超过200种语言。它整合了多社交平台功能，支持WhatsApp、Line、Telegram、Zalo，同时提供账号多开支持，专为助力跨境企业出海拓展设计。
            </div>
          </div>
          <div class="flex-1" style="margin-top: -50px;">
            <img style="mix-blend-mode: multiply;width: 500px;margin-left: 100px;opacity: .7;float: right;" src="../assets/image/bg1.png" alt="">
          </div>
        </div>
        <img class="bgImg min-width" src="../assets/image/bg.png" alt="">
      </div>
      <div class="platform-intro">
        <div class="title">支持多平台</div>
        <div class="flex justify-around max-width">
          <div class="platform-item" style="width:136px">
            <img src="@/assets/image/whatsapp.png" alt="" />
          </div>
          <div class="platform-item" style="width:136px">
            <img src="@/assets/image/telegram.png" alt="" style="" />
          </div>
          <div class="platform-item" style="width:136px">
            <img src="@/assets/image/zalo.png" alt="" style="" />
          </div>
          <div class="platform-item" style="width:136px">
            <img src="@/assets/image/line.png" alt="" style="" />
          </div>
        </div>
      </div>
      <div class="function">
        <div class="max-width">
          <div class="title">功能特点</div>
          <div class="flex justify-between">
            <div class="item">
              <img src="@/assets/image/fun1.png" alt="">
              <div class="item-keywords">实时双向翻译</div>
              <div class="item-intro">
                探索无界沟通： 聊天室搭载先进多功能翻译器，让语言不再成为障碍。覆盖全球超过200种语言。一切操作简单直观，实时同步翻译，让每一次对话都流畅无阻。
              </div>
              <ul class="item-tag flex flex-wrap">
                <li>多通道切换</li>
                <li>200+语言覆盖</li>
                <li>双向语言设置</li>
                <li>即时智能翻译</li>
                <li>GPT翻译对接</li>
              </ul>
            </div>
            <div class="item">
              <img src="@/assets/image/fun2.png" alt="">
              <div class="item-keywords">多平台账号多开</div>
              <div class="item-intro">
                跨平台无障碍营销： 一站式多平台多账号聚合，仅需一次扫码登录，便可轻松管理全球社交媒体账号。支持多开，支持WhatsApp，Telegram，LINE，Zalo等平台。
              </div>
              <ul class="item-tag flex flex-wrap">
                <li>多平台登录</li>
                <li>支持账号多开</li>
                <li>未读消息提示</li>
                <li>自定义IP登录</li>
              </ul>
            </div>
            <div class="item">
              <img src="@/assets/image/fun3.png" alt="">
              <div class="item-keywords">精细化客户管理</div>
              <div class="item-intro">
                独家管理工具：灵龙翻译助理自行研发的客户管理工具，支持工单分享，数据查看。
              </div>
              <ul class="item-tag flex flex-wrap">
                <li>营销分流链接</li>
                <li>数据工单报表</li>
                <li>粉丝计数</li>
                <li>引流活链</li>
              </ul>
            </div>
            <div class="item">
              <img src="@/assets/image/fun4.png" alt="">
              <div class="item-keywords">智能营销</div>
              <div class="item-intro">
                不同平台粉丝集中管理，有效分析市场趋势，持续维护客户，提升客户满意度和忠诚度，为企业赢得更多市场份额。
              </div>
              <ul class="item-tag flex flex-wrap">
                <li>客户备注修改</li>
                <li>快捷回复</li>
                <li>消息群发</li>
                <li>陌生人对话</li>
              </ul>
            </div>
          </div>
        </div>

      </div>
      <div class="data" ref="dataRef">
        <div class="title">灵龙翻译助理-企业出海营销的得力助手</div>
        <div class="flex justify-between max-width flex-wrap">
          <div class="item flex justify-between items-center" v-for="(item, index) in dataList" :key="index">
            <p>{{ item.title }}</p>
            <p class="num" :id="`item${index + 1}`">0%</p>
          </div>
        </div>
      </div>
      <div class="price" id="price">
        <div class="title">价格方案</div>
        <div class="intro">端口收费，字符不限，尽情畅聊</div>
        <el-button class="breath-btn white-btn" @click="showService = true">点击获取价格方案</el-button>
      </div>
      <div class="use">
        <div class="title">谁在使用灵龙翻译助理</div>
        <div class="flex max-width">
          <div class="left-box">
            <p class="left-box-title">我们的优势</p>
            <div>
              <div class="item flex">
                <div class="sort">
                  <div class="sort-inner">1</div>
                </div>
                <p class="flex-1">200+语言翻译支持，GPT接入，双向翻译，接收发送单独设置</p>
              </div>
              <div class="item flex">
                <div class="sort">
                  <div class="sort-inner">2</div>
                </div>
                <p class="flex-1">完善的客户管理，客户引流，客户留存，客户管理，客户转化，全链路监管</p>
              </div>
              <div class="item flex">
                <div class="sort">
                  <div class="sort-inner">3</div>
                </div>
                <p class="flex-1">报表数据全面掌握，全方位多维度提供数据统计，为精准营销做好准备</p>
              </div>
              <div class="item flex">
                <div class="sort">
                  <div class="sort-inner">4</div>
                </div>
                <p class="flex-1">聚合平台，不同平台客户只需灵龙翻译助理一个客户端即可同时登录，支持账号多开，多点触达</p>
              </div>
            </div>
          </div>
          <div class="right-box flex-1 relative" ref="tagsRef">
            <div :class="['tag', item.className, 'tag-' + (index + 1)]"
              :style="{ 'animation-delay': `${index + 1}00ms` }" v-for="(item, index) in tagList">{{ item.value }}</div>
          </div>
        </div>
      </div>
      <div class="free-trial flex justify-center items-center">
        <div>
          <div class="title">灵龙翻译助理，服务全球客户</div>
          <el-button class="breath-btn" type="primary" size="large" @click="showService = true">点击免费试用</el-button>
        </div>
      </div>
      <div class="faqs" id="faqs">
        <div class="title">FAQs</div>
        <div class="flex max-width justify-between" style="height: 500px;">
          <img style="height: 460px;" src="@/assets/image/img1.png" alt="">
          <div class="question-box">
            <div class="item" :class="{ 'current': currentFaq == item.key }" v-for="item in faqList" :key="item.key"
              @click="handleFaq(item)">
              <div class="item-question flex justify-between items-center">
                <p>{{ item.question }}</p>
                <i class="el-icon-arrow-right"></i>
              </div>
              <div class="item-answer">{{ item.answer }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="download-box flex items-end" id="download">
        <div class="flex max-width justify-between">
          <div class="intro">
            <p>多个社交平台 多开聚合聊天</p>
            <p>实时粉丝管理 支持粉丝查重</p>
            <p>多重聚合翻译 覆盖全部场景</p>
            <p>消息双向翻译 多条翻译线路</p>
            <p>人工智能回复 自定角色场景</p>
            <el-button class="breath-btn icon-download-box" type="primary" size="large" @click="download()">
              <div class="flex justify-center items-center">
                <img class="icon-download" src="@/assets/image/icon-download-white.png" alt="">
                <span>客户端下载</span>
              </div>
            </el-button>
          </div>
          <img class="product-img" src="@/assets/image/img2.png" alt="">
        </div>
      </div>
      <div class="about-box" id="about">
        <div class="title">关于我们</div>
        <div class="flex max-width">
          <div class="product-intro-box flex flex-col justify-between">
            <div style="z-index: 1;">
              <img style="width: 80px;" src="@/assets/image/logo3.png">
              <div class="product-title">灵龙翻译助理</div>
              <div class="product-intro">
                灵龙翻译助理是一款集实时双向多语言翻译、客户关系管理于一体的跨境私域管理营销工具，支持超过200种语言，全球交流无障碍。它整合了多社交平台功能，支持WhatsApp、Line、Telegram，Zalo，同时提供账号多开支持，专为提升跨境企业沟通效率和市场拓展效果设计。核心优势包括即时多语言翻译、高准确性、全面隐私保护及多平台适应性，满足外贸、电商和海外推广等领域的全方位需求。
              </div>
            </div>
            <div class="bottom-box">
              <p>点击领取 <span @click="showService = true">免费试用</span></p>
              <p>关注我们的 <span @click="toChannelPage()">频道页</span></p>
            </div>
          </div>
          <div class="menu flex flex-col justify-center">
            <p class="menu-item" v-for="item in menu" :key="item.id" @click="handleSelect(item.id)">{{ item.title }}</p>
          </div>
        </div>
      </div>
      <ServiceModal :show="showService" @change="showService = false"></ServiceModal>
      <img v-show="showBackTop" class="icon-backTop" src="@/assets/image/icon-backTop.png" alt=""
        @click="handleSelect(1)" />
    </el-main>
    <Footer @showService="showService = true"></Footer>
  </div>

</template>

<script>
import ServiceModal from "../components/openService.vue";
import Header from "../components/header.vue";
import Footer from "../components/footer.vue";
import { animateCount } from "@/utils/index";
export default {
  data() {
    return {
      showService: false,
      // isBottom: false,
      currentFaq: null,
      showBackTop: false,
      faqList: [
        {
          key: 1,
          question: "1、我可以在不同设备登录使用灵龙翻译助理吗?",
          answer: "是的，您可以在不同的电脑设备上登录使用灵龙翻译助理，登录账号之后，您的所有数据将同步到该台设备上。"
        },
        {
          key: 2,
          question: "2、灵龙翻译助理 安全吗?",
          answer: "当然，灵龙翻译助理无法查看，也无法保存您的任何聊天数据，而且我们针对各个端口做了加密处理，保护您的个人隐私。"
        },
        {
          key: 3,
          question: "3、灵龙翻译助理 支持哪些社交软件?",
          answer: "灵龙翻译助理聚合了WhatsApp、Line、Telegram、Zalo、Facebook等多款海外热门社交软件，其他软件也将在后续的更新中不断添加进来。"
        },
        {
          key: 4,
          question: "4、灵龙翻译助理 翻译的语言准确吗?",
          answer: "我们提供了专业的语言翻译功能，为了提高翻译的精准度，在使用过程中，请您尽量使用官方的，标准的表达方式，避免使用地方性、区域性的表达方式。"
        }
      ],
      menu: [
        {
          id: 1,
          title: "首页",
        },
        {
          id: 2,
          title: "价格",
        },
        {
          id: 4,
          title: "关于灵龙翻译助理"
        },
        {
          id: 5,
          title: "联系我们"
        },
        {
          id: 7,
          title: "客户端下载"
        }
      ],
      tagList: [
        { key: 1, value: '跨境电商运营', className: 'h-lineH-40' },
        { key: 2, value: '引流工作室', className: 'h-lineH-44' },
        { key: 3, value: '跨国旅游业', className: 'h-lineH-44' },
        { key: 4, value: '跨平台客户维护', className: 'h-lineH-44' },
        { key: 5, value: '海外社媒运营', className: 'h-lineH-48' },
        { key: 6, value: '广告主', className: 'h-lineH-48' },
        { key: 7, value: '多语言客户服务', className: 'h-lineH-48' },
        { key: 8, value: '项目出海', className: 'h-lineH-46' },
        { key: 9, value: '私域引流结算', className: 'h-lineH-48' },
        { key: 10, value: '解决多社媒维护难', className: 'h-lineH-54' }
      ],
      isDataLoaded: false,
      dataList: [
        { title: '运营成本有效降低', percentage: 60 },
        { title: '客服效率提升', percentage: 80 },
        { title: '用户转化率提升', percentage: 70 },
        { title: '客户满意度', percentage: 92 }
      ]
    };
  },
  components: {
    ServiceModal,
    Header,
    Footer
  },
  methods: {
    download() {
      let date = new Date().getTime();
      window.open(
        `https://version.linglongkeji.im/lastVerson/Linglong_setup.exe?time=${date}`
      );
    },
    // kefu(){
    // },
    toBottom(i) {
      let clientHeight =
        document.documentElement.clientHeight || document.body.clientHeight;
      let scrollHeight = document.documentElement.scrollHeight;
      let rollheight = scrollHeight - clientHeight; //超出窗口上界的值就是底部的scrolTop的值
      document.documentElement.scrollTop += 200;
      let c;
      if (document.documentElement.scrollTop + 1 <= rollheight) {
        //之所以+1，可以打印这两个值的日志就知道了，下面+1也是同理
        c = setTimeout(() => this.toBottom(i), 10); //调用setTimeout是为了“回到底部”这过程不是一瞬间
      } else {
        clearTimeout(c);
      }
    },
    scrollHandle(e) {
      // let clientHeight =
      //   document.documentElement.clientHeight || document.body.clientHeight;
      // let scrollHeight = document.documentElement.scrollHeight;
      // let rollheight = scrollHeight - clientHeight;
      // let top = e.srcElement.scrollingElement.scrollTop; // 获取页面滚动高度
      // if (rollheight <= top + 1) {
      //   this.isBottom = true;
      // } else {
      //   this.isBottom = false;
      // }
      let top = e.srcElement.scrollingElement.scrollTop;
      if (top > 100) {
        this.showBackTop = true;
      } else {
        this.showBackTop = false;
      }
    },
    handleFaq(item) {
      if (this.currentFaq == item.key) {
        this.currentFaq = null
      } else {
        this.currentFaq = item.key
      }
    },
    handleSelect(id) {
      switch (id) {
        case 1:
          // 首页
          window.scrollTo({
            top: 0,
            behavior: 'smooth'
          });
          break;
        case 2:
          // 价格
          const el = document.getElementById('price');
          if (el) {
            window.scrollTo({
              top: el.offsetTop - 90,
              behavior: 'smooth'
            });
          }
          break;
        case 4:
          // 关于灵龙翻译助理
          let aboutEle = document.getElementById('about');
          if (aboutEle) {
            window.scrollTo({
              top: aboutEle.offsetTop - 90,
              behavior: 'smooth'
            });
            if (aboutEle.classList.contains('animated')) {
              aboutEle.classList.remove('animated');
            }
            setTimeout(() => {
              aboutEle.classList.add('animated');
            }, 0)
          }
          break;
        case 5:
          // 联系我们
          this.showService = true
          break;
        case 7:
          // 客户端下载
          this.download()
          break;
        default:
          break;
      }
    },
    isElementInViewport(element) {
      const options = {
        root: null, // 观察者根元素，默认为浏览器视口
        threshold: 1 // 触发回调的交叉比例
      };
      const observer = new IntersectionObserver((entries) => {
        // 回调函数中的 entries 包含被观察元素的信息
        if (entries[0].isIntersecting) {
          this.$refs.tagsRef.classList.add('animated')
        }
      }, options);

      observer.observe(element);
    },
    toChannelPage() {
      // 跳转频道
      window.open('https://t.me/linglongchuhai')
    },
    numBeat(element) {
      const options = {
        root: null, // 观察者根元素，默认为浏览器视口
        threshold: 1 // 触发回调的交叉比例
      };
      const observer = new IntersectionObserver((entries) => {
        // 回调函数中的 entries 包含被观察元素的信息
        if (entries[0].isIntersecting) {
          if (!this.isDataLoaded) {
            this.dataList.forEach((item, index) => {
              animateCount(0, item.percentage, 1000, `item${index + 1}`, '%')
            })
            this.isDataLoaded = true
          }
        }
      }, options);

      observer.observe(element);
    }
  },
  mounted() {
    window.addEventListener("scroll", this.scrollHandle); //绑定页面滚动事件
    this.$refs.productInfoRef.classList.add('show')
    this.isElementInViewport(this.$refs.tagsRef)
    this.numBeat(this.$refs.dataRef)
  },
};
</script>

<style lang="postcss" scoped>
body>.el-container {
  margin-bottom: 40px;
}

.banner-box {
  height: 900px;
  padding-top: 90px;
  position: relative;
}

.bgImg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: -1;
}

.product-info {
  transform: translateX(-800px);
  margin-top: -160px;

  &.show {
    transition: transform .5s linear;
    transform: translateX(0);
  }

  .title {
    /* font-size: 100px; */
    font-size: 4.7rem;
    color: #3269FF;
    font-weight: bold;

    span {
      color: #222A41;
    }
  }

  .slogan {
    font-size: 40px;
    background-color: #FFE540;
    border-radius: 44px;
    color: #5E4F05;
  }

  .intro {
    border: 2px solid #fff;
    font-size: 20px;
    color: #6D7795;
    border-radius: 16px;
    margin-top: 18px;
    padding: 10px;
    text-align: left;
  }
}

.platform-intro {
  padding-top: 50px;
  padding-bottom: 30px;
  background: linear-gradient(180deg, #F9FCFF 0%, #CCEDFA 100%);

  .title {
    font-size: 48px;
    font-weight: bold;
    color: #253858;
    margin-bottom: 36px;
  }

  .platform-item:hover {
    animation: breath .5s linear infinite alternate;
  }
}

.function {
  padding-top: 70px;
  padding-bottom: 76px;
  background: linear-gradient(180deg, #CCEDFA 0%, #F9FCFF 100%);

  .title {
    font-size: 48px;
    font-weight: bold;
    color: #253858;
    margin-bottom: 120px;
  }

  .item {
    width: 285px;
    height: 388px;
    background: radial-gradient(0% 67% at 50% 50%, #FAFDFF 0%, #EBF5FF 100%);
    border-radius: 16px;
    padding: 0 15px;
    position: relative;
    transition: transform 0.3s linear;

    img {
      display: block;
      margin: 0 auto;
      margin-top: -70px;
    }

    .item-keywords {
      font-size: 24px;
      color: #3269FF;
      font-weight: bold;
      margin-top: 10px;
      margin-bottom: 5px;
    }

    .item-intro {
      background-color: #fff;
      padding: 10px;
      font-size: 16px;
      color: #222A41;
      border-radius: 8px;
      height: 170px;
      line-height: 26px;
      text-align: left;
    }

    .item-tag {
      color: #237CC9;
      margin-top: 4px;

      li {
        width: 50%;
        position: relative;
        text-align: left;
        padding-left: 18px;
        margin-top: 8px;

        &::before {
          content: '';
          width: 10px;
          height: 10px;
          background-color: #237CC9;
          display: block;
          border-radius: 50%;
          position: absolute;
          top: 50%;
          left: 0;
          margin-top: -5px;
        }
      }
    }

    &:hover {
      transform: translateY(-30px);
    }
  }
}

.data {
  height: 700px;
  padding-top: 70px;
  background: url('../assets/image/bg9.png') no-repeat;
  background-size: 100% 100%;

  .title {
    font-size: 48px;
    font-weight: bold;
    color: #253858;
    margin-bottom: 138px;
  }

  .item {
    width: 550px;
    height: 100px;
    padding: 20px 30px;
    margin-bottom: 60px;
    font-size: 21px;
    color: #253858;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.8) 0%, #EFF6FF 100%);
    border-radius: 16px;
    border: 2px solid #FFFFFF;

    .num {
      font-size: 48px;
      color: #3269FF;
      font-weight: bold;
    }
  }
}

.price {
  padding-top: 60px;
  height: 400px;
  color: #fff;
  background: url('../assets/image/bg3.png') no-repeat;
  background-size: 100% 100%;

  .title {
    font-size: 48px;
    font-weight: bold;
    margin-bottom: 40px;
  }

  .intro {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 50px;
  }
}

.use {
  padding-top: 60px;
  height: 800px;
  background: linear-gradient(132deg, #C3E2F6 0%, #fff 100%);

  .title {
    font-size: 48px;
    font-weight: bold;
    color: #253858;
    margin-bottom: 74px;
  }

  .left-box-title {
    font-size: 36px;
    color: #3269FF;
    margin-bottom: 10px;
    text-align: left;
  }

  .item {
    width: 500px;
    background: #FFFFFF;
    padding: 15px 20px;
    margin-bottom: 15px;
    box-shadow: -8px -8px 20px 0px #F8FFFF, 8px 8px 20px 0px rgba(166, 174, 189, 0.46);
    border-radius: 16px;
    font-size: 20px;
    color: #6D7795;
    text-align: left;

    .sort {
      width: 60px;
      height: 60px;
      background: rgba(240, 243, 249, 0.93);
      box-shadow: inset -10px -10px 20px 0px rgba(255, 255, 255, 0.7), inset 10px 10px 20px 0px rgba(131, 131, 131, 0.1);
      border-radius: 20px;
      padding: 5px;
      position: relative;
      margin-right: 15px;

      .sort-inner {
        width: 50px;
        height: 50px;
        background: linear-gradient(132deg, #3269FF 0%, #89F7FE 100%);
        border-radius: 14px;
        position: absolute;
        left: 5px;
        font-size: 32px;
        color: #fff;
        line-height: 50px;
        text-align: center;
      }
    }
  }

  .right-box {
    .tag {
      background: #3269FF;
      box-shadow: 2px 8px 20px 0px rgba(58, 137, 255, 0.2);
      border-radius: 8px;
      color: #fff;
      position: absolute;
      opacity: 0;
    }

    .tag-1 {
      top: 64px;
      left: 422px;
      width: 110px;
      font-size: 14px;
    }

    .tag-2 {
      top: 150px;
      left: 500px;
      width: 120px;
      font-size: 16px;
    }

    .tag-3 {
      top: 226px;
      right: 182px;
      width: 120px;
      font-size: 16px;
    }

    .tag-4 {
      top: 304px;
      right: 60px;
      width: 146px;
      font-size: 16px;
    }

    .tag-5 {
      top: 38px;
      right: 374px;
      width: 148px;
      font-size: 17px;
    }

    .tag-6 {
      top: 140px;
      left: 300px;
      width: 120px;
      font-size: 17px;
    }

    .tag-7 {
      top: 400px;
      left: 334px;
      width: 164px;
      font-size: 18px;
    }

    .tag-8 {
      top: 303px;
      left: 248px;
      width: 108px;
      font-size: 18px;
    }

    .tag-9 {
      top: 386px;
      left: 95px;
      width: 151px;
      font-size: 18px;
    }

    .tag-10 {
      top: 194px;
      left: 40px;
      width: 220px;
      font-size: 22px;
    }

    &.animated .tag {
      animation-name: leftIn;
      animation-duration: 1s;
      animation-timing-function: ease;
      animation-fill-mode: forwards;
    }
  }
}

.free-trial {
  height: 500px;
  color: #fff;
  background: url('../assets/image/bg5.png') no-repeat;
  background-size: cover;
  .title {
    font-size: 48px;
    font-weight: bold;
    margin-bottom: 70px;
  }
}

.faqs {
  padding-top: 70px;
  height: 700px;
  background: url('../assets/image/bg6.png') no-repeat;
  background-size: cover; 
  .title {
    font-size: 48px;
    font-weight: bold;
    margin-bottom: 36px;
  }

  .question-box {
    width: 600px;

    .item {
      margin-bottom: 20px;
      transition: all 0.3s;

      .item-question {
        padding: 0 30px;
        line-height: 70px;
        background: #222A41;
        color: #fff;
        font-size: 21px;
        border-radius: 16px;
        cursor: pointer;
      }

      .item-answer {
        margin-top: 9px;
        padding: 0 30px;
        color: #6D7795;
        font-size: 20px;
        text-align: left;
        display: none;
      }

      &.current {
        .el-icon-arrow-right {
          transform: rotate(90deg);
          transition: all 0.3s;
        }

        .item-answer {
          display: block;
          transition: all 0.3s;
        }
      }
    }
  }
}

.download-box {
  height: 700px;
  background: linear-gradient(180deg, #F3F6FD 0%, #C2DDF0 100%);

  .intro {
    margin-top: 85px;
    font-size: 21px;
    color: #0f5b89;
    margin-bottom: 20px;

    p {
      position: relative;
      padding-left: 30px;
      margin-bottom: 12px;

      &::before {
        content: '';
        width: 15px;
        height: 15px;
        background: #3269FF;
        border-radius: 50%;
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: -7.5px;
      }
    }

    .icon-download-box {
      margin-top: 32px;

      .icon-download {
        height: 26px;
        margin-right: 10px;
      }

      &:hover {
        .icon-download {
          content: url('../assets/image/icon-download-blue.png')
        }
      }
    }
  }

  .product-img {
    width: 900px;
    /* margin-right: -144px; */
  }
}

.about-box {
  padding-top: 70px;
  height: 800px;
  background: linear-gradient(132deg, #fff 0%, #9AC7E6 100%);

  .title {
    font-size: 48px;
    font-weight: bold;
    margin-bottom: 70px;
    color: #fff;
  }

  .product-intro-box {
    width: 900px;
    height: 512px;
    background: #fff;
    box-shadow: 8px 18px 20px 0px rgba(19, 100, 182, 0.4);
    border-radius: 24px;
    position: relative;
    text-align: left;
    padding: 30px;

    &::before {
      content: "";
      height: 100%;
      width: 100%;
      background: url("../assets/image/bg7.png") no-repeat;
      position: absolute;
      right: 0;
      top: -69px;
      background-size: 100% 100%;
    }

    .product-title {
      margin: 19px 0 16px 0;
      font-size: 36px;
      color: #222A41;
      font-weight: bold;
    }

    .product-intro {
      font-size: 21px;
      color: #6D7795;
    }

    .bottom-box {
      width: 200px;
      color: #222A41;
      font-size: 21px;
      z-index: 1;

      p {
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0;
        }

        span {
          color: #3269FF;
          text-decoration: underline;
          cursor: pointer;
        }
      }

      &:not(:hover) {
        span {
          animation: blink 1s linear infinite alternate;
        }
      }
    }
  }

  .menu {
    width: 230px;
    height: 512px;
    background: #fff;
    margin-left: 30px;
    box-shadow: 8px 18px 20px 0px rgba(19, 100, 182, 0.4);
    border-radius: 24px;

    .menu-item {
      font-size: 21px;
      color: #222A41;
      margin-bottom: 20px;
      cursor: pointer;

      &:hover {
        color: #3269FF;
        text-decoration: underline;
      }
    }
  }

  &.animated {
    .product-intro-box {
      animation-name: leftIn;
      animation-duration: 1s;
      animation-timing-function: ease;
      animation-fill-mode: forwards;
      /* animation-delay: 1s; */
    }
  }
}

.icon-backTop {
  position: fixed;
  bottom: 250px;
  right: 30px;
  cursor: pointer;
}

.breath-btn {
  animation: breath .5s linear infinite alternate;
}

.service-p {
  position: fixed;
  bottom: 220px;
  right: 13px;
  width: 200px;
  height: 39px;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  /* background-color: #FFFFFFFF; */
  /* border: 2px solid #3F8CFFFF; */
}

.service-p1 {
  position: fixed;
  bottom: 300px;
  right: 50px;
  width: 128px;
  height: 39px;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  /* background-color:#3F8CFFFF; */
}

.ma {
  font-size: 18px;
  color: #6d7795;
  font-weight: 400;
  font-family: PingFangSC-Regular, PingFang SC;
  width: 290px;
  line-height: 27px;
  margin-left: 25px;
}

/* .service-Txt{
  font-size: 14px;
  color: #3F8CFFFF;
  margin-left: 5px;
} */

.service-Txt1 {
  font-size: 14px;
  color: #ffffffff;
  margin-left: 5px;
}

.el-card__body,
.el-main {
  /* padding: 20px 0 !important; */
}

.el-main {
  color: #333;
  /* text-align: center; */
  /* margin-top: -78px; */
  width: 100%;
  padding: 0 !important;
  padding-bottom: 120px !important;
}
</style>
