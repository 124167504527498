import axios from "axios"
// import qs from 'qs'
// import { isEmpty } from 'lodash-es'

// import { Toast } from 'vant'

const request = axios.create({
  baseURL: "https://office-api.jonesoutsea.com",
  // baseURL: 'http://192.168.0.37:9900',
  timeout: 10000,
  headers: {
    "Content-Type": "application/json",
  },
})

// 添加请求拦截
request.interceptors.request.use(
  (config) => {
    const authInfo = JSON.parse(sessionStorage.getItem("__authInfo__") ?? "{}")

    // if (!isEmpty(authInfo)) {
    //   // 设置 axios 请求头
    //   config.headers!.Authorization = `${authInfo.token_type} ${authInfo.access_token}`
    // }

    // if (config.method === 'get' || config.method === 'delete') {
    //   config.paramsSerializer = (params) => qs.stringify(params, { indices: false })
    // }

    return config
  },
  (error) => {
    // 错误的传参
    return Promise.reject(error)
  }
)

// 添加响应拦截器
request.interceptors.response.use(
  (response) => {
    const { code, msg, data } = response.data
    if (code == 1) {
      return response.data
    } else {
      //   Toast.fail(msg)
      return Promise.reject(msg)
    }
  },
  (error) => {
    const { msg } = error.response.data
    // Toast.fail(msg)
    // 返回接口返回的错误信息
    return Promise.reject(error.response.data)
  }
)

export default request
