<template>
  <div class="header-box" ref="headerRef">
    <div class="header-con max-width flex justify-between items-center">
      <img class="header_logo" style="cursor: pointer;" src="../assets/image/logo.png" alt="" @click="toHome()" />
      <div class="flex">
        <div class="navs relative">
          <div class="nav" ref="navRefs" :class="activeIndex === key ? 'current' : ''" v-for="(item, key) in menu"
            :key="key" @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave" @click="handleSelect(key,item)">
            <div class="nowrap">{{ item.title }}</div>
          </div>
          <div class="bgBar" ref="bgBarRef"></div>
        </div>
        <div class="ml-1">
          <el-button type="primary" @click="toLogin()">登录 / 注册</el-button>
          <el-button type="info" @click="handleFreeTrial()">免费试用</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      // collapsed: false,
      activeIndex:0,
      menu:[
        {
          id: 1,
          title: "首页",
        },
        {
          id: 2,
          title: "价格",
        },
        // {
        //   id:3,
        //   title:"出海资讯"
        // },
        {
          id:4,
          title:"关于灵龙翻译助理"
        },
        {
          id:5,
          title:"联系我们"
        },
        {
          id:7,
          title:"客户端下载"
        },
        {
          id:6,
          title:"FAQs"
        }
      ]
    };
  },
  methods: {
    handleMouseEnter(el){
      if(el.target.classList.contains("current")) return
      this.$refs.navRefs.map(ele => {
        ele.style.color = '#252D43'
        // ele.style.fontWeight = "normal";
      })
      el.target.style.color = "#fff";
      // el.target.style.fontWeight = "bold";
      this.$refs.bgBarRef.style.width = `${el.target.offsetWidth}px`;
      this.$refs.bgBarRef.style.left = `${el.target.offsetLeft}px`;
      this.$refs.bgBarRef.classList.add("animationNav");
      setTimeout(() => {
        this.$refs.bgBarRef.classList.remove("animationNav");
      }, 500);
    },
    handleMouseLeave(el){
      if(el.target.classList.contains("current")) return
      // el.target.style.color = specialPath.value ? "#fff" : '#252D43';
      el.target.style.color = ''
      // el.target.style.fontWeight = ''
      this.scrollToCurrent()
    },
    scrollToCurrent(){
      let currentDom = this.$refs.navRefs.find(el => el.classList.contains("current"))
      if(currentDom){
        this.$refs.bgBarRef.style.width = `${currentDom.offsetWidth}px`;
        this.$refs.bgBarRef.style.left = `${currentDom.offsetLeft}px`;
        currentDom.style.color = "#fff";
        // currentDom.style.fontWeight = "bold";
        this.$refs.bgBarRef.classList.add("animationNav");
        setTimeout(() => {
          this.$refs.bgBarRef.classList.remove("animationNav");
        }, 500);
      }else{
        this.$refs.bgBarRef.style.width = '0px';
        this.$refs.bgBarRef.style.left =  '0px';
      }
    },
    handleSelect(key,item){
      this.activeIndex = key;
      switch (item.id) {
        case 1:
          // 首页
          window.scrollTo({
            top: 0,
            behavior: 'smooth'
          });
          break;
        case 2:
          // 价格
          let ele = document.getElementById('price');
          if (ele) {
            window.scrollTo({
              top: ele.offsetTop - 90,
              behavior: 'smooth'
            });
          }
          break;
        case 3:
          // 出海资讯
          // window.open('https://www.dujiaoshou.ws/djs_tag?tagid=3&name=%E7%8B%AC%E8%A7%92%E5%85%BD%E7%BF%BB%E8%AF%91%E5%99%A8/%E8%AE%A1%E6%95%B0%E5%99%A8')
          window.open('http://www.wealthsync.top/#/p_index')
          break;
        case 4:
          // 关于灵龙翻译助理
          let aboutEle = document.getElementById('about');
          if (aboutEle) {
            window.scrollTo({
              top: aboutEle.offsetTop - 90,
              behavior: 'smooth'
            });
            if(aboutEle.classList.contains('animated')){
              aboutEle.classList.remove('animated');
            }
            setTimeout(() => {
              aboutEle.classList.add('animated');
            },0)
          }
          break;
        case 5:
          // 联系我们
          this.handleFreeTrial()
          break;
        case 6:
          // FAQS
          let el = document.getElementById('faqs');
          if (el) {
            window.scrollTo({
              top: el.offsetTop - 90,
              behavior: 'smooth'
            });
          }
          break;
        case 7:
          // 客户端下载
          // this.download()
          let downloadEl = document.getElementById('download');
          if (downloadEl) {
            window.scrollTo({
              top: downloadEl.offsetTop - 90,
              behavior: 'smooth'
            });
          }
          break;
        default:
          break;
      }
    },
    scrollHandle(e){
      let top = e.srcElement.scrollingElement.scrollTop;
      if(top>30){
        this.$refs.headerRef.classList.add('setBg')
      }else{
        if(this.$refs.headerRef.classList.contains('setBg')){
          this.$refs.headerRef.classList.remove('setBg')
        }
      }
    },
    toLogin(){
      window.open('https://user-console.linglongkeji.im/#/Login')
    },
    toHome(){
      this.$refs.navRefs.map(ele => {
        ele.style.color = '#252D43'
        // ele.style.fontWeight = "normal";
      })
      this.handleSelect(0,this.menu[0])
      this.$nextTick(()=>{
        this.scrollToCurrent()
      })
    },
    handleFreeTrial(){
      this.$emit('showService')
    },
    download(){
      this.$emit('download')
    }
  },
  mounted() {
    this.scrollToCurrent()
    window.addEventListener("scroll", this.scrollHandle); //绑定页面滚动事件
  },
}
</script>
<style scoped>
.header_logo{
  height: 65px;
}
.header-box {
  width:100%;
  position:fixed;
  top:0;
  z-index: 1;
  padding-top:10px;
}
.header-box.setBg{
  background-color: #fff;
}
.header-con{
  line-height: 80px;
  height: 80px;
}
.navs {
  flex: 1;
  display: flex;
}
.navs .nav {
  margin: 0 10px;
  height: 100%;
  box-sizing: border-box;
  height: 80px;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  white-space: nowrap;
  padding:0 15px;
  color: #252D43;
  transition: all 0.5s;
}
.navs .bgBar{
  position: absolute;
  top: 26px;
  left: 0;
  width: 48px;
  height: 28px;
  border-radius: 14px;
  background: #3269FF;
  z-index: -1;
  transition: left 0.3s linear;
}
.animationNav{
  animation: shake 0.4s infinite;
}
@keyframes shake {
  0% { transform: translateX(0); } 
  25% { transform: translateX(-6px) rotateY(3deg); }
  75% { transform: translateX(6px) rotateY(-3deg); }
  100% { transform: translateX(0); }
}
</style>