<template>
  <el-dialog
    title="联系客服"
    :visible.sync="isShow"
    :width="width || '514px'"
    :show-close="false"
    class="service_modal"
    :before-close="closeModal"
  >
    <div
      @click="closeModal"
      style="position: absolute; cursor: pointer; top: 20px; right: 14px"
    >
      <img
        src="../assets/image/close.png"
        style=""
        alt="灵龙翻译助理出海拓客引流客服系统"
      />
    </div>

    <div style="text-align: center">
      <div style="margin-bottom: 20px">
        <img
          class="m-auto"
          src="../assets/image/kefu1.png"
          alt="灵龙翻译助理出海拓客引流客服系统"
        />
      </div>
      <div style="width: 380px; margin: 0 auto ; text-align: left; ">
        <div
          class="dia_span row"
          v-for="(item, index) in serviceList"
          :key="item.id"
        >
          <span class="type">{{ item.contactType }}:</span>
          <span class="name" @click="linkChange(item.serviceUrl)">
            {{ item.name }}
          </span>
        </div>
      </div>
      <div class="tip_box">
        <div class="tip ">
           <div class="tip_icon"></div>
        </div>
        <div class="tip tip_text">以上客服均可联系，因当前咨询人数过多，发消息无回应时，可尝试联系其他客服</div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import { getCustomerService } from "../api/api.js";
export default {
  props: {
    show: {
      type: Boolean,
    },
    width: {
      type: String,
    },
  },
  data() {
    return {
      serviceList: [],
      isShow: false,
    };
  },
  methods: {
    getServiceData() {
      this.serviceList = [
        {
          "id": 1,
          "name": "shouzhang88",
          "serviceUrl": "https://t.me/shouzhang88",
          "contactType": "商务Telegram"
        },
        {
          "id": 2,
          "name": "zkk9999999",
          "serviceUrl": "https://t.me/zkk9999999",
          "contactType": "商务Telegram"
        },
        {
          "id": 3,
          "name": "linglongkeji",
          "serviceUrl": "https://t.me/linglongkeji",
          "contactType": "客服Telegram"
        },
        {
          "id": 4,
          "name": "灵龙科技",
          "serviceUrl": "https://t.me/linglongchuhai",
          "contactType": "官方频道页"
        }
      ];
      // getCustomerService().then((res) => {
      //   if (res.code == 1) {
      //     this.serviceList = res.data.list;
      //   }
      // });
    },
    linkChange(link) {
      window.open(link);
    },
    closeModal() {
      this.isShow = false;
      this.$emit("change", false);
    },
  },
  watch: {
    show: {
      handler(newval) {
        this.isShow = newval;
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.getServiceData();
  },
};
</script>
<style lang="postcss" scoped>
/* 弹框 */
.dialog_tit {
  text-align: center;
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #262e44;
  margin-top: 30px;
}
.dia_span {
  line-height: 50px;
  background: rgba(240,243,249,0.7);
  box-shadow: inset -10px -10px 20px 0px rgba(255,255,255,0.8), inset 10px 10px 20px 0px rgba(0,0,0,0.2);
  border-radius: 8px;
  margin-bottom:10px;
  color:#262E44;
  font-size: 18px;
  cursor: pointer;
  font-weight: 500;
  .name{
    color: #3269FF;
    text-decoration: underline;
    margin-left:35px;
    font-size: 16px;
  }
  .type{
    margin-left: 56px;
  }
}
.tip_box{
  width: 380px;
  margin: 0 auto;
  display: flex;
  .tip:first-child{
    flex: 30px;
  }
  .tip_icon{
    margin-top: 2px;
    width: 14px;
    height: 14px;
    border: 4px solid #b5d9e2;
    background: #2FB9CA;
    border-radius: 100%;
  }
  .tip_text{
    font-size: 14px;
    color: #959EB0;
    line-height: 21px;
    text-align: left;
  }
}
.dialog_name {
  /* font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #262e44;
  margin-right: 5px;
  width: 100px; */
}
.service_modal ::v-deep.el-dialog{
  height: auto;
  padding-bottom: 10px;
  background: #E3E6EC;
  border-radius: 16px;
}
.service_modal ::v-deep.el-dialog__header {
  /* margin-right: 0; */
  padding:0;
  padding-top:52px;
  .el-dialog__title{
    font-size: 24px;
    color: #262E44;
  }
}
.service_modal ::v-deep.el-dialog__body {
  padding:20px;
  padding-bottom:30px;
}
</style>
