import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '../views/index.vue'

Vue.use(VueRouter)
const redirectPath = /Android|webOS|iPhone|iPod|BlackBerry|iPad/i.test(
  navigator.userAgent
)
  ? "/m_index"
  : "/p_index";
const routes = [
  {
    path:'/',
    redirect:redirectPath,
  },
  {
    path:'/p_index',
    name:'p_index',
    component:index,
    meta: {
      title: '首页',
      type:'pc',
    },
  },
  {
    path: '/p_home',
    name: 'p_home',
    component: () => import('../views/home.vue'),
    children:[
      // {
      //   path:'/p_index',
			// 	name:'p_index',
			// 	component:index,
      //   meta: {
      //     title: '首页',
      //     type:'pc',
      //   },
      // },
      // {
      //   path:'/p_download',
			// 	name:'p_download',
			// 	component:() => import('../components/download.vue'),
      //   meta: {
      //     title: '下载',
      //     type:'pc',
      //   },
      // },
      // {
      //   path:'/p_price',
			// 	name:'p_price',
			// 	component:() => import('../components/price.vue'),
      //   meta: {
      //     title: '价格',
      //     type:'pc',
      //   },
      // },
      // {
      //   path:'/p_commonproblem',
			// 	name:'p_commonproblem',
			// 	component:() => import('../components/commonproblem.vue'),
      //   meta: {
      //     title: '常见问题',
      //     type:'pc',
      //   },
      // },
    ]
  },
  // H5
  {
    path: '/m_home',
    name: 'm_home',
    component: () => import('../views/mobile/home.vue'),
    children:[
      {
        path:'/m_index',
				name:'m_index',
				component: () => import('../views/mobile/index.vue'),
        meta: {
          title: '首页',
          type:'mobile',
        },
      },
      {
        path:'/m_download',
				name:'m_download',
				component:() => import('../views/mobile/download.vue'),
        meta: {
          title: '下载',
          type:'mobile',
        },
      },
      {
        path:'/m_price',
				name:'m_price',
				component:() => import('../views/mobile/price.vue'),
        meta: {
          title: '价格',
          type:'mobile',
        },
      },
      {
        path:'/m_downsan',
				name:'m_downsan',
				component:() => import('../views/mobile/downsan.vue'),
        meta: {
          title: '常见问题',
          type:'mobile',
        },
      },
    ]
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 200 }
    }
  }
})

export default router
