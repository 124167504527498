import { render, staticRenderFns } from "./openService.vue?vue&type=template&id=46a71dbe&scoped=true"
import script from "./openService.vue?vue&type=script&lang=js"
export * from "./openService.vue?vue&type=script&lang=js"
import style0 from "./openService.vue?vue&type=style&index=0&id=46a71dbe&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/.store/vue-loader@15.11.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46a71dbe",
  null
  
)

export default component.exports