/**
 * 动画计数
 * @param oldValue 初始值
 * @param newValue 最终值
 * @param duration 持续时间
 * @param idEle idDom元素
 * @param symbol 符号/单位
 */
export const animateCount = (oldValue, newValue, duration,idEle,symbol) => {
  let range = newValue - oldValue;
  let current = oldValue;
  let elapsed = 0;
  let increment = range / duration * 10;

  let timer = setInterval(function() {
    elapsed += 20;
    current += increment;
    if (elapsed >= duration) {
      clearInterval(timer);
      current = newValue;
    }
    document.getElementById(idEle).innerHTML = Math.ceil(current).toString() + symbol;
  }, 10);
}