<template>
  <div class="footer-box">
    <div class="footer-con max-width flex justify-between items-center">
      <img style="cursor: pointer;width: 124px;" src="../assets/image/logo.png" alt="" />
      <p class="slogan">聚合社交平台，畅聊<span style="color:#EECE00">不限字符</span></p>
      <el-button type="primary" size="large" @click="handleFreeTrial()">免费试用</el-button>
    </div>
  </div>
</template>
<script>
export default{
  data(){
    return{

    }
  },
  methods: {
    handleFreeTrial(){
      this.$emit('showService')
    }
  },
}
</script>
<style scoped>
.footer-box{
  width:100%;
  height: 120px;
  background: linear-gradient(132deg, #6d6a6a 0%, #9AC7E6 100%);
  position: fixed;
  bottom:0;
  color:#fff;
  z-index: 1;
}
.slogan{
  font-size: 36px;
}
.footer-con{
  height: 100%;
}
</style>